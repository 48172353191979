import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../../Services/generalservice.service';
import { NgxCaptureService } from 'ngx-capture';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    enquiery: any = [];
    HomeUrl: any;
    Fullname: any;
    email: any;
    Mobile: any;
    constructor(private captureService: NgxCaptureService, private viewportScroller: ViewportScroller, public generalService: GeneralService, private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {


    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {
       

    }
    Name = '';
    Number = '';
    CompanyName = '';
    Email = '';
    Message = '';
    @ViewChild('screen', { static: true }) screen: any;

    capture() {
        debugger

        this.captureService.getImage(this.screen.nativeElement, true).subscribe(img => {
            console.log(img);
            this.imgBase64 = img
            this.save()
        })
    }

    DataURIToBlob(dataURI: string) {
        debugger
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    ip = "http://localhost:62161/Images"

    save() {
        debugger
        const file = this.DataURIToBlob(this.imgBase64)
        const formData = new FormData();
        formData.append('file', file, 'image.png')
        let url = "upload2.php"
        this.http.post(this.ip + url, formData).subscribe(data => {
            // this.generalService.PostData(url, UploadFile).then((data: any) => {


        })
    }

  
    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var CompanyName = form.CompanyName;
        console.log(CompanyName);
        var message = form.message;
        console.log(message);
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }




    sendmail() {
         
        this.enquiery = [];
        this.enquiery.push({
            FarmerName: this.Fullname,
            EmailID: this.email,
            Comments: this.Message,
            Mobile: this.Mobile

        })
        var uploadfile = new FormData;
        uploadfile.append("Email", JSON.stringify(this.enquiery));
        var url = "api/NovusChem/EnquiryMailTo_Novuschem";
        this.generalService.PostData(url, uploadfile).then(data => {
             
            this.generalService.ShowAlert("SUCCESS", 'Mail sent Successfuly', 'success');
            Swal.fire('SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success')
            this.Fullname = '';
            this.Mobile = '';
            this.email = '';
            this.Message = '';

            //setTimeout(() => {
            //    window.location.reload();
            //}, 2000);
        });

    }


}
