<div id="about" class="about-area ptb-100">
    <div id="loader" style="display: none;">
        <div class="spinner"></div>
    </div>
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products Details</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:black;"> <i class="fas fa-home"></i> Home >>&nbsp;</a>
                </li>
                
                <!-- Display category name obtained from localStorage -->
                <li style="color:black;" (click)="GetProductsByCategory1(productDetails[0].CategoryId)">
                    {{ getCategoryNameFromLocalStorage() }} >>&nbsp;
                </li>

                <li class="" style="color:black;" > {{ProName}}</li>
            </ul>


        </div>
    </nav>

    <div class="container">
        <div class="row mt-4">
            <div class="col-lg-2">
                <div class="image-container" style="width: 114%;">
                    <img src="../../../assets/Images/Novuschem_logo.png" />
                </div>
                <div class="cont">
                    <div>Documents</div>
                    <div class="cat" (click)="GetCoa()" style="cursor:pointer">MSDS</div>
                    <div class="cat" (click)="GetCoa()" style="cursor:pointer">COA</div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="contact-form">
                    <div class="cat">{{Catalog_No}}</div>
                    <div class="prod fw-bold">{{ProductName1}}</div>
                    <div class="cas">{{CAS_No}}</div>
                    <div class="inrusd">
                        <button type="button"
                                (click)="setCurrency('IND')"
                                [ngClass]="{'active': activeCurrency === 'IND'}"
                                style="border: 1px solid #80808073;">
                            IND
                        </button>
                        &nbsp;
                        <button type="button"
                                (click)="setCurrency('USD')"
                                [ngClass]="{'active': activeCurrency === 'USD'}"
                                style="border: 1px solid #80808073;">
                            USD
                        </button>
                    </div>

                    <table class="mt-5">
                        <thead class="thh">
                            <tr class="table11">
                                <th scope="col">Packs</th>
                                <th scope="col" style="cursor:pointer">
                                    Price ({{ currency === 'USD' ? '$' : '₹' }})
                                </th>
                                <th>Dis Price</th>
                                <th scope="col">Availabilty</th>
                                <th scope="col" class="text-center">Qty (No)</th>
                                <th scope="col" class="text-center" *ngIf="loginDetails && loginDetails[0]?.RoleId == 3">Special Price</th>
                                <th scope="col" class="text-left">Cart</th>

                            </tr>

                        </thead>

                        <tbody class="table11">
                            <tr class="table11" *ngFor="let item of category1">
                                <!--<td scope="col">{{item.PackName}}&nbsp; {{item.UOM}}</td>-->
                                <td scope="col">{{item.PackName}}</td>

                                <td scope="col" *ngIf="loginDetails == null ">
                                    <ng-container *ngIf="item.PackPrice == 1; else originalPrice">
                                        <strong>POR</strong>
                                    </ng-container>
                                    <ng-template #originalPrice>
                                        <ng-container *ngIf="currency === 'IND'">
                                            {{ item.PackPrice }}
                                        </ng-container>
                                        <ng-container *ngIf="currency === 'USD'">
                                            {{ convertToUSD(item.PackPrice) | number:'1.2-2' }}
                                        </ng-container>
                                        </ng-template>
                                </td>
                                <td scope="col" *ngIf="loginDetails !== null">
                                    <ng-container *ngIf="currency === 'USD';">
                                        <h6>
                                            {{ convertToUSD(item.PackPrice) | number:'1.2-2' }}
                                        </h6>
                                    </ng-container>
                                    <ng-container *ngIf="item.PackPrice == 1; else originalPrice">
                                        <strong>POR</strong>
                                    </ng-container>
                                    <ng-template #originalPrice>
                                        <h6 [ngStyle]="{'color': loginDetails[0]?.Discount !== null ? 'red' : 'inherit', 'font-size': loginDetails[0]?.Discount !== null ? '11px' : 'inherit'}"
                                            *ngIf="profiledata[0].Discount != 0">
                                            {{ item.PackPrice | number:'1.2-2' }}
                                        </h6>

                                    </ng-template>
                                </td>
                                <ng-container *ngIf="item.PackPrice == 1; else originalPrice">
                                    <strong>POR</strong>
                                </ng-container>
                                <ng-template #originalPrice>
                                    <td scope="col" *ngIf="loginDetails !== null">
                                        <h6 *ngIf="loginDetails[0]?.Discount !== null">
                                            {{ discountstotal(item).toFixed(2) }}
                                        </h6>
                                    </td>
                                </ng-template>
                                <td scope="col" *ngIf="loginDetails == null && item.PackPrice != 1" style="color: #d03737;font-weight: 700;"><a (click)="goToLogin()">Sign in</a></td>
                                <td scope="col" *ngIf="loginDetails !== null">{{Availabilty}}</td>
                                <td scope="col" *ngIf="loginDetails == null" style="color: #d03737;font-weight: 700;"><a (click)="goToLogin()">Sign in</a></td>
                                <td scope="col">
                                    <div id="Quantity" class="quantiti quantity-button" style="margin-left: 64px;" *ngIf="loginDetails == null">
                                        <span (click)="decreaseQuantity(item)" style="cursor:pointer;">-</span>
                                        <span>{{ item.Quantity }}</span>
                                        <span (click)="increaseQuantity(item)" style=" cursor:pointer;">+</span>
                                    </div>
                                    <div id="Quantity" class="quantiti quantity-button" style="margin-left: 20px;" *ngIf="loginDetails !== null">
                                        <span (click)="decreaseQuantity(item)" style="cursor:pointer;">-</span>
                                        <span>{{ item.Quantity }}</span>
                                        <span (click)="increaseQuantity(item)" style=" cursor:pointer;">+</span>
                                    </div>
                                </td>

                                <td scope="col" *ngIf="loginDetails && loginDetails[0]?.RoleId == 3">
                                    <input type="tel" placeholder="Enter Special Price" class="dis" [(ngModel)]="item.Discount" />
                                </td>
                                <td scope="col" (click)="item.PackPrice != 1 && Addtocart(productDetails[0], item)">
                                    <i class="fa fa-cart-plus cart-icon" [ngClass]="{ 'disabled-icon': item.PackPrice == 1 }"></i>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-lg-5">
                            <button class="Custom2" (click)="toggleCustomEnquiry()">Bulk Enquiry</button>
                        </div>
                        <div class="col-lg-7">
                            <div *ngIf="showCustomEnquiry" class="row">

                                <div class="col-lg-6">
                                    <label>Qty<span style="color:red">*</span></label>
                                    <input class="add_input" type="text" placeholder="Enter Quantity" [(ngModel)]="Quantity1" />
                                </div>

                                <div class="col-lg-6">
                                    <label for="category">UOM<span style="color:red">*</span></label>
                                    <div class="add_input uomm">
                                        <p-autoComplete [(ngModel)]="UOM" [suggestions]="deptList" (completeMethod)="searchCont1($event)"
                                                        field="UOM" [dropdown]="true" [size]="90"
                                                        placeholder="Select UOM" [minLength]="1"
                                                        [style]="{'color': 'black', 'height':'33px'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                        </p-autoComplete>
                                    </div>
                                    <small *ngIf="form.controls.UOM.touched && form.controls.UOM.errors?.required"
                                           class="p-error" style="color:red">UOM is required.</small>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div class="row" *ngIf="showCustomEnquiry">

                        <div class="col-lg-9">
                            <label>Remarks</label>
                            <textarea class="add_input" placeholder="Enter Remarks" style="width: -webkit-fill-available;" [(ngModel)]="Comments"></textarea>
                        </div>

                        <div class="col-lg-3 text-right">
                            <button class="btn   btnn" (click)="InsertBulkorders(Quantity1, UOM, Comments)" [disabled]="!Quantity1 || !UOM" [ngClass]="{'disabled-button': !Quantity1 || !UOM}">Submit</button>
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-lg-3 color">
                <div class="color">
                    <div class="prodd ">Related Categories</div>
                    <hr style="width: 74%;"/>
                    <div class="vertical-pills">
                        <ul class="nav nav-pills flex-column ">
                            <li class="nav-item" *ngFor="let item of originalCategory">

                                <a class="nav-link" style="color:black;font-size:14px"
                                   [routerLink]="['/Products']"
                                   [class.active]="activeCategoryID === item.CategoryID"
                                   (click)="getFilteredProductCount(item.CategoryID)">
                                    {{ item.CategoryName }}
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

        </div>
            <div class="row">
                <div class="col-lg-9 mt-5">
                        <div class="product-info-table">
                            <table class="table1">
                                <tr>
                                    <td class="pinfo">Product Info</td>
                                    <td class="star">*This product is to be used for research and laboratory purposes only.</td>
                                </tr>
                                <tr>
                                    <td>Product Name</td>
                                    <td>{{ProductName1}}</td>
                                </tr>
                                <tr *ngIf="Catalog_No">
                                    <td>Catalog No</td>
                                    <td>{{Catalog_No}}</td>
                                </tr>
                                <tr *ngIf="CAS_No">
                                    <td>CAS No</td>
                                    <td>{{CAS_No}}</td>
                                </tr>
                                <tr *ngIf="HSN">
                                    <td>HSN</td>
                                    <td>{{HSN}}</td>
                                </tr>
                                <tr *ngIf="Molecular_Formula">
                                    <td>Molecular Formula</td>
                                    <td>{{Molecular_Formula}}</td>
                                </tr>
                                <tr *ngIf="Molecular_Weight">
                                    <td>Molecular Weight</td>
                                    <td>{{Molecular_Weight}}</td>
                                </tr>
                                <tr *ngIf="Inchi">
                                    <td>Inchi</td>
                                    <td>{{Inchi}}</td>
                                </tr>
                                <tr *ngIf="InchiKey">
                                    <td>InchiKey</td>
                                    <td>{{InchiKey}}</td>
                                </tr>
                                <tr *ngIf="Tax">
                                    <td>Tax</td>
                                    <td>{{Tax}}</td>
                                </tr>
                                <tr *ngIf="ISO_Smiles">
                                    <td>ISO Smiles</td>
                                    <td>{{ISO_Smiles}}</td>
                                </tr>
                                <!--<tr *ngIf="Availabilty">
                                    <td>Availability</td>
                                    <td>{{Availabilty}}</td>
                                </tr>-->
                                <tr class="safety">
                                    <td colspan="2" style="text-align:center">For the Material Safety Data Sheet, write to <b><u>support-in@novuschems.com</u></b></td>
                                </tr>


                            </table>
                        </div>

                     

                    
                </div>

                <div class="col-lg-3">
                    <img src="../../../assets/Images/bestquality.jpg"  class="certificate"/>
                    <img src="../../../assets/Images/fast_delivery.png" class="certificate1"/>
                    <img src="../../../assets/Images/ProductCustomization.png" class="certificate1"/>
                </div>

            
    </div>
    <br />

</div>

<style>
       .table1 {
        width: 100%;
        border-collapse: collapse;
    }

    .table1 td {
        padding: 5px;
        border: 1px solid #ddd;
    }

    .table1 tr:nth-child(odd) {
        background-color: white;
    }

        .table1 tr:nth-child(even) {
            background-color: #d037371a;
        }

    .pinfo {
        font-weight: bold;
    }

    .star {
        text-align: right;
        font-style: italic;
    }

    .safety {
        background-color: #f8f8f8;
        font-weight: bold;
    }

    * {
        box-sizing: border-box;
    }

    .img-zoom-container {
        position: relative;
    }

    .img-zoom-lens {
        position: absolute;
        border: 1px solid #d4d4d4;
        width: 40px;
        height: 40px;
    }

    .img-zoom-result {
        border: 1px solid #d4d4d4;
        width: 300px;
        height: 300px;
    }


    tr.break td {
        height: 10px;
    }
</style>

