import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpHeaders } from '@angular/common/http';
import { NgxCaptureService } from 'ngx-capture';
import { ViewportScroller } from '@angular/common';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-request-for-quote',

  templateUrl: './request-for-quote.component.html',
  styleUrls: ['./request-for-quote.component.scss']
})
export class RequestForQuoteComponent {   
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    HomeUrl: any;
    selectedFile: File;
    imagefile: string | ArrayBuffer;
    image: any;
    token: any;

    constructor(private captureService: NgxCaptureService, private viewportScroller: ViewportScroller, public generalService: GeneralService, private http: HttpClient, private fb: FormBuilder, public Rm: ReactiveFormsModule) {
       
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {


    }
    CustomerName = '';
    ContactNumber = '';
    EmailID = '';
    R_ProductName = '';
    R_CAS_No = '';
    R_PackName = '';
    Qty = '';
    Description = '';
    @ViewChild('screen', { static: true }) screen: any;

    capture() {
        debugger

        this.captureService.getImage(this.screen.nativeElement, true).subscribe(img => {
            console.log(img);
            this.imgBase64 = img
            this.save()
        })
    }

    DataURIToBlob(dataURI: string) {
        debugger
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    ip = "http://localhost:62161/Images"

    save() {
        debugger
        const file = this.DataURIToBlob(this.imgBase64)
        const formData = new FormData();
        formData.append('file', file, 'image.png')
        let url = "upload2.php"
        this.http.post(this.ip + url, formData).subscribe(data => {
            // this.generalService.PostData(url, UploadFile).then((data: any) => {


        })
    }



    //onFileChange(event: any) {
    //    const file = event.target.files[0];
    //    if (file) {
    //        const reader = new FileReader();
    //        reader.onload = () => {
    //            this.imagefile = reader.result;
    //        };
    //        reader.readAsDataURL(file);
    //    }
    //}

    onSubmit(form: any) {
        debugger;

        this.EnquiryAry = [];
        this.EnquiryAry.push({
            CustomerName: form.name,
            ContactNumber: form.number,
            R_ProductName: form.ProductName,
            R_CAS_No: form.CAS_No,
            R_PackName: form.PackName,
            Qty: form.Qty,
            EmailID: form.email,
            Description: form.description,
            Specifications_Purity: this.imagefile
        });

        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        var url = "api/NovusChem/RequestedproductsMail_ToCustomer";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;

            this.onSubmit11(form);
            Swal.fire(
                'SUCCESS',
                'Your Requested Product has been submitted successfully',
                'success'
            ).then(() => {
                // Reload the page
                window.location.reload();
            });
        });
    }
    detectFiles(input) {
        debugger

        const reader = new FileReader();
        if (input.files.length) {
            debugger
            const file = input.files[0];
            reader.onload = () => {
                debugger
                this.imagefile = reader.result;
                this.image = this.imagefile;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "doc" || extFile == "pdf") {
                debugger
                var UploadFile = new FormData();
                UploadFile.append("PostedFile", file);
                var url = 'api/NovusChem/Upload_Attachment';
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        debugger
                        this.imagefile = data;
                        this.image = data;
                    }
                });
            }
            else {
                alert("Only doc/jpg/jpeg, png files are allowed!");
            }

        }
    }


    onFileSelected(event: any) {
        debugger
        this.selectedFile = event.target.files[0] as File;
    }
    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var ProductName = form.ProductName;
        console.log(ProductName);

        var CAS_No = form.CAS_No;
        console.log(CAS_No);

        var PackName = form.PackName;
        console.log(PackName);

        var Qty = form.Qty;
        console.log(Qty);

        var description = form.description;
        console.log(description);
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }

    onSubmit11(form) {
        debugger;
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            debugger;
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            let arr = [];
            arr.push({
                CustomerName: form.name,
                ContactNumber: form.number,
                R_ProductName: form.ProductName,
                R_CAS_No: form.CAS_No,
                R_PackName: form.PackName,
                Qty: form.Qty,
                EmailID: form.email,
                Description: form.description,
                Specifications_Purity: form.Specifications_Purity,
                AttachSpecification: this.imagefile
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(arr));
            UploadFile.append("Flag", '1');
            var url = this.HomeUrl + "api/NovusChem/NC_RequestProduct_CRUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}` // Prefix "Bearer" is a common convention for JWT tokens
            });

            this.http.post(url, UploadFile, { headers }).subscribe(data => {
                debugger;
                Swal.fire('SUCCESS', 'Your request submitted successfully', 'success').then(() => {
                    // Reload the page
                    window.location.reload();
                });
            }, err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
            });
        });
    }
}
